// Importing Ant Design CSS
// ANT-Design Doc: https://ant.design/docs/react/getting-started
// @import 'antd/dist/antd.css';

// If you want to override variables do it here
@import "variables";

// If you want to override mixins do it here
@import "form";

// If you want to add custom font do it here
@import "fonts";

// If you want to add something do it here
@import "custom";

// Antdesign utility classes
@import 'antd-css-utilities/utility.min.css';


/* ===== Scrollbar CSS ===== */
/* Firefox */
input * {
  scrollbar-width: auto;
  scrollbar-color: #e3e3e3 transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #e3e3e3;
  border-radius: 10px;
  border: 2px solid #ffffff;
}