@import "../../../../assets/sass/variables";

.link-wrapper {
    width: 100%;

    img {
        border: 1px solid #f3eff2;
        padding: rem(6);
        border-radius: rem(2);
        font-size: rem(25);
        margin-top: rem(10);
    }

    .link-text {
        color: $lighttextcolor;
    }
}