@import "../../../../assets/sass/variables";

.roles-wrapper {
    .add-button {
        padding: rem(9) rem(14) !important;
        max-width: 40.6%;
        border-radius: rem(3);
        font-size: large;
        font-weight: 400;
        border: rem(1) solid $lighttextcolor;
        background-color: $colorwhite !important;
        color: #717b85 !important;
    }

    .remove-icon {
        color: #717b85 !important;
    }

    .ant-select-selection-placeholder {
        color: #717b85 !important;
    }

    .ant-select-arrow, .ant-select-clear {
        color: #717b85 !important;
        margin-top: rem(1) !important;
    }
}


@media screen and (max-width:rem(767)) {
    .roles-wrapper {
        .add-button {
            max-width: 82.5%;

        }
    }
}


.margin {
    margin-right: rem(8);
}

.custom-collapse {
    max-width: rem(300);
    border: none;
    background-color: transparent;
}

.scrollable-container {
    max-height: rem(80);
    overflow-y: auto;
    scrollbar-width: thin;
}

.panel-content {
    border: rem(1) solid #e8e8e8;
    border-radius: rem(5);
    padding: rem(15);
    width: rem(267);
    overflow: auto;
    position: relative;
    left: rem(-16);
}

.ant-collapse-item {
    border: none !important;
}


.ant-collapse-header {
    border: rem(1) solid #e8e8e8;
    border-radius: rem(5);
    margin-bottom: rem(10);
}

.check-item {
    padding: 0;
    margin-bottom: 5px;
    cursor: pointer;
    height: 70px;
}

.check-item.active {
    background: $colorprimary;
}
.check-item .content {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    position: relative;

    .minus-icon {
        position: absolute;
        left: 0px;
        top: 2px;
        font-size: 18px;
        z-index: 100;
        background: $colorprimary;
        border-radius: 4px;
        pointer-events: none;

        svg {
            fill: $colorsecondary;
            color: $colorprimary;
        }
    }
    
}
.items-list-box {
    background: $colordull;
    border-radius: 5px;
    padding: 10px;
    margin-left: 30px;
    .item {
        margin-bottom: 10px;
    }
}

.col-section {
    height: 100%;
    overflow: auto;
    width: 400px;
    max-width: 100%;
}

.compliance-box {
    max-height: calc(100vh - 250px);
    max-width: fit-content;
    width: 100%;
    margin: 0 auto;

    .h-full  {
        height: 100% !important;
        overflow: auto;
       
        /* width */
        ::-webkit-scrollbar {
            width: 10px;
        }
        
        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
        }
        
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .text-medium-gray {
        color: $lighttextcolor;
        padding: 10px 0 20px;
    }
}
