@use "sass:math";

$html-font-size: 16px;

@function stripUnit($value) {
    @return math.div($value, $value * 0 + 1);
}

@function rem($pxValue) {
    @return #{math.div(stripUnit($pxValue), stripUnit($html-font-size))}rem;
}

//Color Vars

$colorwhite: #fff;
$colorblack: #000;
$colorprimary: #ccff00;
$colorsecondary: #35424A;
$colorcontent: #374254;
$border-color: #DEE2E6;
$color-green:#27B973;
$color-green-table-row: #e7f5b3;
$color-yellow:#efa22f;
$field-bg: #eef0f2;
$colorred: #f70c0c;
$heading-color: #68727d;
$colorauth:#E31837;
$lighttextcolor:#68727d;
$bordercolor:#f3f4f7;
$colorpink:#ffdfe4;
$colordull: #eff0f1;

//Font Family

$font-secondary: 'Share', cursive;