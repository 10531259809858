@import "../../../../assets/sass/variables";


/* Set tick color to green */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $colorsecondary !important;
  ::after{
     border-color: $colorprimary !important;
     background-color: $colorprimary !important;
  }
}

.ant-checkbox .ant-checkbox-inner::after {
  border-color: $colorprimary !important;
}

