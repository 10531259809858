@import "../../../assets/sass/variables";

.creationModal{
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 0;
}
.onlyLoad{
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 0;
  padding-right: 30px;
}

.modalText{
  text-align: center;
}

.modal-Wrapper{
// width: 300px;
}
.loadingHead{
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 23px;
  height: fit-content !important;
  margin-block: 0px !important;
}
#createNewButton{
background-color:white !important;
  border-radius: 8px;
  padding-block:  8px;
  padding-inline:  12px;
  font-size: 14px;
  margin-right: 10px;
  font-weight: 600;
}
#openButton{
background-color:#35424A !important;
  border-radius: 8px;
  padding-block:  8px;
  padding-inline:  12px;
  border:none;
  padding: 9px;
  color: white;
  font-size: 14px;
  margin-right: 10px;
  font-weight: 600;
}
.loadingFooter{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.successIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: greenyellow;
  border-radius: 50%;
  padding: 15px;
  // height: 20px;
  // width: 20px;
  font-weight: 600;
  margin: rem(24);
  font-size: 30px;
}

.check{
  font-weight: 800;
}

.project-wrapper {
  margin-top: rem(60);

  .content-wrapper {
    padding: rem(15) rem(5);
    flex: 1;
    margin-top: 20px;
  }


  .toggle-buttons {
    margin-top: rem(24);
    display: flex;
    justify-content: space-between;
  }

  .ant-steps-item-title::after {
    position: absolute;
    top: rem(16);
    inset-inline-start: 100%;
    display: block;
    border: rem(1) dashed;
    background: gray
  }

  .ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: $colorprimary;
    border-color: $colorsecondary;
    

    .ant-steps-icon {
      color: $colorsecondary !important;
    }
  }

  .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: $colorsecondary;
    border-color: $colorprimary;

    .anticon {
      color: $colorprimary;
    }
    
  }

  .ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title , .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title  {
    color: $colorsecondary !important;
  }
  
  .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after{
    background-color: transparent;
  }

 
  .ant-steps .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon{
    color: $colorprimary;
  }

  .ant-steps .ant-steps-item-wait .ant-steps-item-icon{
    background-color: $colorwhite;
    border: rem(1) solid gray;
  }

  .ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title{
    color: gray;
  }

  form {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
  
    .content-wrapper {
      flex: 1;
      overflow: auto;
    }
  }
  

}

.file-uploading.error{
  border: 2px solid red;
  background: #ff000021;
  .cancel {
    top: 20px !important;
  }
}
.file-uploading  {
  .meta {
    display: flex;
    .title {
      font-weight: 600;
      font-size: 14px;
    }
    .desc {
      font-size: 12px;
      color: $lighttextcolor;
    }
    .file-icon {
      font-size: 28px;
      margin-right: 20px;
    }
    .cancel {
      position: absolute;
      right: 20px;
      top: 10px;
      svg{
        font-size: 25px;
      }
    }
  }
  
 }

