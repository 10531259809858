.custom-select {
  .ant-select-selection-item {
    font-weight: 500;
  }
  .ant-select-selector {
    border-radius: 8px !important;
    border-color: #e0e0e0 !important;
  }
  .ant-select-multiple
    .ant-select-selection-overflow
    .ant-select-selection-item {
    padding-inline-start: 2px !important;
  }
  .ant-select-multiple
    .ant-select-selection-overflow
    .ant-select-selection-item-content {
    font-weight: 600;
  }
  .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: transparent !important;
  }
}

.nameCircle {
  background: #ccff00;
  padding: 8px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  color: #35424a;
  position: absolute;
  z-index: 10;
  left: 3px;
  top: 3px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  display: flex;
}

.custom-select-component {
  .ant-select-selection-placeholder {
    padding-left: 40px !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    position: relative;
    text-transform: capitalize;
    padding-left: 40px !important;
  }
}

.custom-select-prefix-ident {
  :is(.ant-select:not(.ant-select-customize-input) .ant-select-selector input) {
    margin-left: -4px !important;
  }
}
:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selection-overflow-item { 
  max-width: 70%;
  text-overflow: ellipsis; 
  margin-right: 2px;
}