@import "../../../../assets/sass/variables";


.project-detail-wrapper{
    max-width: 700px;
    margin: auto;

    .f-lable {
        width: 200px;
    }

    .f-field {
        flex: 1
    }

    p{
        margin: 0;
        font-size: rem(18);
        font-weight: 500;
    }
}