body {
  color: #000000;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  font-size: rem(16);
  background: $colorwhite;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="number"],
select,
textarea,
.ant-input-password {
  padding: rem(10) rem(14);
  // border-radius: 3px;
  font-weight: 400;
  // border: 1px solid $lighttextcolor;
  // background-color: $colorwhite !important;
  // color: $colorwhite !important;
  width: 100%;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #70797d;
    font-size: rem(14);
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #70797d;
    font-size: rem(14);
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #70797d;
    font-size: rem(14);
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: #70797d;
    font-size: rem(14);
  }
}

.ant-input-affix-wrapper > input.ant-input {
  background: $colorwhite !important;
  border: 0;
}

.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  // padding: rem(7) rem(14) !important;
  padding: rem(5) !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 3px;
  padding: rem(5);
  font-weight: 400;
  border: 1px solid $lighttextcolor;
  background-color: $colorwhite !important;
  width: 100%;
  height: auto;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #70797d !important;
    font-size: rem(14);
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #70797d;
    font-size: rem(14);
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #70797d;
    font-size: rem(14);
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: #70797d;
    font-size: rem(14);
  }
}

.ant-form-item .ant-form-item-explain-error {
  margin-top: rem(5);
  // background-color: aqua;
}

.ant-select-selection-placeholder {
  color: gray !important;
}

.ant-select-arrow,
.ant-select-clear {
  color: #717b85 !important;
  margin-top: rem(1) !important;
}

.ant-picker {
  padding: 0 rem(14) 0 0;
  border-radius: 8px;
  font-weight: 400;
  // border: 1px solid $lighttextcolor;
  border-color: #e0e0e0;
  width: 100%;
  background-color: $colorwhite !important;

  .ant-picker-input > input {
    line-height: normal;
    padding: rem(12) rem(14);

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #70797d;
      font-size: rem(14);
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #70797d;
      font-size: rem(14);
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #70797d;
      font-size: rem(14);
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: #70797d;
      font-size: rem(14);
    }
  }
}

a {
  color: $colorprimary;

  &:hover {
    opacity: 0.9;
    color: $colorprimary;
  }
}

//Generic Style

.ant-form-item-label {
  font-weight: 700;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  ):after {
  display: inline-block;
  margin-right: rem(5);
  margin-left: rem(5);
  color: #ff4d4f;
  font-size: rem(14);
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*" !important;
}

label.ant-form-item-required::after {
  display: inline-block;
  margin-right: rem(5);
  margin-left: rem(5);
  color: #ff4d4f;
  font-size: rem(14);
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*" !important;
}

.ant-form-vertical .ant-form-item-label > label::after {
  visibility: visible;
}

.form-group {
  .ant-form-item-label {
    label {
      color: $colorcontent;
    }
  }
}

.ant-form-item-has-error {
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  select,
  textarea,
  .ant-input-password,
  .ant-picker,
  .ant-select-selector {
    border: 1px solid $colorred !important;
  }
}

.ant-picker {
  cursor: pointer;
}

.link-btn {
  font-size: rem(12);
  border-radius: 5px;
  cursor: pointer;
  border: 0;
  min-width: 120px;
  font-weight: 600;
  min-height: rem(38);
  background: $colorprimary;
  color: black !important;
  a {
    color: black !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  &:hover,
  &:focus {
    background: $colorprimary;
  }
}

.ant-btn {
  font-size: rem(12);
  color: #333;
  border-radius: 5px;
  border: 0;
  // text-transform: uppercase;
  // letter-spacing: 1px;
  font-weight: 600;
  min-height: rem(38);

  &:hover,
  &:focus {
    opacity: 0.85;
    outline: none;
  }

  &:where(.css-dev-only-do-not-override-mxhywb).ant-btn-primary:not(
      :disabled
    ):hover {
    background-color: $colorprimary;
  }

  &:where(.css-dev-only-do-not-override-mxhywb).ant-btn-secondary:not(
      :disabled
    ):hover {
    background-color: $colorsecondary;
  }

  &:where(.css-dev-only-do-not-override-mxhywb).ant-btn-light:not(
      :disabled
    ):hover {
    background-color: #f4f5f6 !important;
  }
}

.ant-btn-primary {
  background: $colorprimary;
  // color: $colorwhite;
  color: black !important;

  &:hover,
  &:focus {
    background: $colorprimary !important;
  }
}

.ant-btn-auth {
  background: $colorsecondary !important;
  color: $colorwhite !important;

  &:hover,
  &:focus {
    background: $colorsecondary;
  }
}

.ant-btn-light {
  background: #f4f5f6 !important;
  color: $lighttextcolor !important;
  width: max-content !important;

  &:hover,
  &:focus {
    background: #f4f5f6;
  }
}

.ant-btn:disabled {
  cursor: not-allowed;
}

.ant-btn-secondary {
  background: $colorsecondary;
  color: $colorwhite;

  &:hover,
  &:focus {
    background: $colorsecondary;
    color: $colorwhite;
  }
}

//Loader

.ant-space {
  &.loader {
    display: inline-flex;
    background: rgba(255, 255, 255, 0.6);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    z-index: 9999;

    .ant-space-item {
      .ant-spin-spinning {
        .ant-spin-dot-spin {
          transform: rotate(0deg);
          -webkit-animation: none;
          animation: none;

          .ant-spin-dot-item {
            display: none;
          }

          &::before {
            content: url(../images/spinner-o.gif);
          }
        }
      }
    }
  }
}
.ant-space {
    &.onlyLoader {
        .ant-space-item {
            .ant-spin-spinning {
                .ant-spin-dot-spin {
                    transform: rotate(0deg);
                    -webkit-animation: none;
                    animation: none;

          .ant-spin-dot-item {
            display: none;
          }

          &::before {
            content: url(../images/spinner-o.gif);
          }
        }
      }
    }
  }
}

.ant-layout {
  background: $colorwhite;
}

.main-head {
  margin-top: 50px;
}

// Content in line

.inline-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Text with Shadow
.shadow-text {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

// Auth Header Text
.auth-header-text {
  font-family: "designsystembw01-700rregular";
  font-weight: bold;
  font-size: rem(30);
}

// Radio Button

.ant-radio-button-wrapper {
  background-color: $colorwhite;
  color: $colorblack;
  height: rem(42);
  // padding: rem(10);
  &:hover {
    color: $colorwhite;
    background-color: $colorauth;
  }
}

.color-primary {
  color: $colorauth;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: $colorprimary !important;
  background: $colorauth !important;
  border: 1px solid #d9d9d9;
  color: $colorblack;
  &:hover {
    background: $colorauth !important;
    border-color: $colorwhite;
  }
  &::before {
    background-color: $colorauth !important;
  }
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: $bordercolor !important;
}

// for cursor
.cursor {
  cursor: pointer !important;
}

// Table Header Style
.ant-table-wrapper .ant-table-thead > tr > th {
  color: $lighttextcolor !important;
  svg {
    height: 12px;
    width: 10px;
  }
}

// Common Search Input

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  border: 1px solid #68727d;
}
.ant-input-group .ant-input-group-addon {
  padding: rem(0) rem(5) !important;
  .ant-btn-default {
    background-color: transparent !important;
  }
}

.show-only-hover { 
  opacity: 0;
}
th:hover .show-only-hover {
  opacity: 1;
}