@import "../../../../assets/sass/variables";

.forgot-wrapper {
  .form-wrapper-2 {
    display: flex;
    justify-content: center;
  }

  .anticon svg {
    color: red !important;
    border: 1px solid #f3eff2;
    padding: rem(6);
    border-radius: rem(2);
    font-size: rem(25);
    margin-top: rem(10);
  }

  p {
    color: $lighttextcolor;
    font-size: rem(15);
  }
}

.drawer_main {
  margin-top: 63px;
  border-top: 1px solid #dee2e6;
  border-left: 2px solid #dee2e6;
  overflow: hidden;
}

.filters {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.notification-type {
  font-size: 12px;
  font-weight: 500;
  color: rgba(#000000, 0.6);
  align-self: baseline;
}

.notification-title {
  font-size: 16px;
  font-weight: 600;
  color: rgba(#000000, 0.87);
}

.personnel {
  font-size: 12px;
  font-weight: 700;
  color: rgba(#000000, 0.87);
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.due-date {
  font-size: 12px;
  font-weight: 400;
  color: rgba(#000000, 0.6);
}
